import React from 'react';
import {
  StickyBanner,
  Carousel,
  Hero,
  Svg,
  ComponentVerticalSpacing,
  ComponentWrapper,
  EmailSignupModal,
  ADDRESS_BOOKS,
  BREAKPOINTS,
  Media,
  Card,
  CardSlider
} from 'day8-react/build/new-components';
import { PILL_TYPES } from 'day8-react/build/new-components/constants';
import PageLayout from '../layouts/page';
import Reviews from './front-page/Reviews';
import Svg2 from './front-page/Svg';
import type { FrontPage } from '../pages';
import Payment from './how-it-works/Payment';
import { analyticsHelpers } from 'day8-react';
import Image from 'next/image';
import Link from 'next/link';
import AnchorSymbol from 'day8-react/build/new-components/Svg/TYWSymbols/Anchor';
import Sunset from 'day8-react/build/new-components/Svg/TYWSymbols/Sunset';
import FancySun from 'day8-react/build/new-components/Svg/TYWSymbols/FancySun';
import TribeSymbol from 'day8-react/build/new-components/Svg/TYWSymbols/Tribe';
import { useLuckyModal } from '../context/LuckyModalContext';

const trustPilot = {
  link: '#reviews',
  score: '4-5',
  color: '#ffffff'
};


const welcome = {
  welcomeSections: [
    {
      heading: "Sail",
      text: "Eat, sleep and live on a yacht for seven days in a paradise of your choosing and enjoy the flexibility yacht travel brings.",
      link: {
        url: "/media",
        text: "Gallery"
      },
      videoUrl: "https://assets.theyachtweek.com/wp-content/uploads/2024/04/Sailing-360p.mp4",
      poster: "https://assets.theyachtweek.com/wp-content/uploads/2024/03/sail.png"
    },
    {
      heading: "Explore",
      text: "Fill your days with secret swim stops, discovering deserted beaches and hiking to heights that make your heart soar.",
      link: {
        url: "/destination-guide",
        text: "Destinations"
      },
      videoUrl: "https://assets.theyachtweek.com/wp-content/uploads/2024/04/Explore-video.mp4",
      poster: "https://assets.theyachtweek.com/wp-content/uploads/2024/03/explore.png"
    },
    {
      heading: "Dance",
      text: "Dance somewhere different every night of the week. Boogie on a boat, a bar or a beach - we'll dance anywhere!",
      link: {
        url: "/destination-guide",
        text: "Festivals"
      },
      videoUrl: "https://assets.theyachtweek.com/wp-content/uploads/2024/04/Dancing-video.mp4",
      poster: "https://assets.theyachtweek.com/wp-content/uploads/2024/03/dance.png"
    },
    {
      heading: "Belong",
      text: "After experiencing your first Yacht Week, you're initiated into a community of sailors, makers, movers and shakers.",
      link: {
        url: "/why-yacht-week",
        text: "Reviews"
      },
      videoUrl: "https://assets.theyachtweek.com/wp-content/uploads/2024/04/Belong-360p.mp4",
      poster: "https://assets.theyachtweek.com/wp-content/uploads/2024/03/belong.png"
    }
  ]
};



const HomepageWrapper = ({ page, isAdsLandingPage, mainDestinations, festivals, howItWorksPage }: FrontPage) => {
  const { toggle, showNewFunnel } = useLuckyModal();
  return (
    <PageLayout page={page} canonical="https://www.theyachtweek.com/">
      <div className="position-relative">
        {
          // Output sticky banner only if some text is entered
          page.acf.flex_sticky_banner && page.acf.flex_sticky_banner.text !== '' && (
            <div className="width-100% position-absolute@sm d8-c-sticky-banner-contain d8-u-zindex-header">
              <StickyBanner
                text={page.acf.flex_sticky_banner.text}
                link={page.acf.flex_sticky_banner.link || null}
              />
            </div>
          )
        }
        <Carousel settings={{ autoplay: true, autoplaySpeed: 11000 }}>
          {page.acf.homeheroslides.map(slide => {
            const cta = slide.button;
            if (typeof window !== "undefined") {
              cta.onClick = () => {
                analyticsHelpers.clickedHomePageHeaderCTA();
              };
              if (showNewFunnel) {
                cta.link = null;
                cta.onClick = () => {
                  analyticsHelpers.clickedHomePageHeaderCTA();
                  toggle();
                };
              }
            }
            return (
              <Hero
                key={JSON.stringify(slide)}
                subtitle={slide.subtitle}
                bgImage={slide.background}
                video={slide.video}
                template={slide.video?.url ? 'Video' : 'Basic'}
                cta={cta}
                mobileVideo={slide.mobile_video}
                mobileBgImage={slide.mobile_background}
                overlay={!!slide.title || !!slide.subtitle}
                whiteFont
                countdown={slide.countdown}
                fullHeight
                hideLastName={isAdsLandingPage}
                trustLogos={!isAdsLandingPage}
                signUpForm={isAdsLandingPage}
                trustPilot={trustPilot}
                addressBookId={isAdsLandingPage ? ADDRESS_BOOKS['tyw-welcome'] : null}
                title={slide.title}
              />
            );
          })}
        </Carousel>
        <a className="hero--scroll-arrow" href="#welcome">
          <Svg name="arrow-down" fill="#fff" />
        </a>
        <div id="welcome" />
        <div className='bottom-tear-contain max-width-100% overflow-hidden'>
          <Svg2 name="bottomTear" className="bottom-tear" fill="hsl(160, 14%, 96%)" />
        </div>
      </div>
      <div>


        {/* Welcome with 4 col grid */}

        <ComponentVerticalSpacing>
          <ComponentWrapper>
            <div className="container max-width-lg margin-auto margin-bottom-xl text-center d8-u-color-brand-primary text-component">
              <h2 className="max-width-md margin-auto d8-u-color-brand-primary">Welcome to the best week of your life</h2>
              <p className="max-width-md margin-top-lg margin-x-auto"><strong>This is Yacht Week - a seven-day adventure and festival like no other.</strong> Join us for seven days of sailing, curated adventures and exclusive parties in some of the most enchanting corners of the world. Explore a new destination every day. Dive into local culture. Dance your nights away. And leave with new friends and a community for life. </p>
            </div>
          </ComponentWrapper>
          <Media lessThan={BREAKPOINTS.MD}>
            <Carousel
              equalHeights
              showEdges
              outerDots
              settings={{
                dots: true,
                autoplay: true,
                autoplaySpeed: 6000,
                slidesToShow: 1,
                slidesToScroll: 1
              }}
            >
              {welcome.welcomeSections.map(section => (
                <ComponentWrapper key={JSON.stringify(section)} >
                  <div className="col height-100% d8-u-color-brand-primary">
                    <div className="flex flex-column items-start height-100%">
                      <div className="relative aspect-4-3 margin-bottom-sm">
                        <video
                          className="video width-100% d8-u-radius-md"
                          loop
                          muted
                          playsInline
                          autoPlay
                          preload='auto'
                          poster={section.poster}
                        >
                          <source src={section.videoUrl} type="video/mp4" />
                        </video>
                        {/* <img className='width-100% d8-u-radius-md' src="https://assets.theyachtweek.com/wp-content/uploads/2020/07/TYW_Croatia_w36_Joakim_Vatnaland_026-4.jpg" width="300" height="150" alt="WSJ" /> */}
                      </div>
                      <h2 className='text-lg margin-bottom-xs d8-u-color-brand-primary'>{section.heading}</h2>
                      <p className='margin-bottom-md flex-grow'>{section.text}</p>
                      <Link className='btn btn--ghost-brand-primary max-width-lg' href={section.link.url}>{section.link.text}</Link>
                    </div>
                  </div>
                </ComponentWrapper>
              ))}
            </Carousel>
          </Media>
          <Media greaterThan={BREAKPOINTS.SM}>
            <div className="d8-l-4-col-grid max-width-xl padding-x-md margin-auto margin-top-md padding-bottom-xl items-start">
              {welcome.welcomeSections.map(section => (
                <div key={JSON.stringify(section)} className="col height-100% d8-u-color-brand-primary">
                  <div className="flex flex-column items-start height-100%">
                    <div className="relative aspect-4-3 margin-bottom-sm">
                      <video
                        className="video width-100% d8-u-radius-md"
                        loop
                        muted
                        playsInline
                        autoPlay
                        preload="auto"
                        poster={section.poster}
                      >
                        <source src={`${section.videoUrl}#t=0.5`} type="video/mp4" />
                      </video>
                      {/* <img className='width-100% d8-u-radius-md' src="https://assets.theyachtweek.com/wp-content/uploads/2020/07/TYW_Croatia_w36_Joakim_Vatnaland_026-4.jpg" width="300" height="150" alt="WSJ" /> */}
                    </div>
                    <h2 className='text-lg margin-bottom-xs d8-u-color-brand-primary'>{section.heading}</h2>
                    <p className='margin-bottom-md flex-grow'>{section.text}</p>
                    <Link className='btn btn--ghost-brand-primary max-width-lg' href={section.link.url}>{section.link.text}</Link>
                  </div>
                </div>
              ))}
            </div>
          </Media>
        </ComponentVerticalSpacing>



        {/* As featured In 3 col grid */}
        <div className='d8-u-brand-primary-bg d8-u-color-brand-secondary padding-top-md padding-bottom-md'>
          <ComponentVerticalSpacing>
            <ComponentWrapper>
              <h2 className="text-center text-lg margin-bottom-sm d8-u-color-brand-secondary">
                As featured in
              </h2>
              <div className="d8-l-3-col-grid max-width-md margin-auto margin-top-md items-center">
                <div className="col">
                  <div className="flex justify-center">
                    <div className="margin-md radius-md text-center relative">
                      <p className='margin-bottom-md'>“The Ultimate M.B.A. Networking Event? A Yacht Party in Croatia.”</p>
                      <Image className='max-width-logo-sm width-auto' src="https://assets.theyachtweek.com/wp-content/uploads/2024/03/WSJ.png" width="300" height="150" alt="WSJ" />
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="flex justify-center">
                    <div className="margin-md radius-md relative text-center">
                      <p className='margin-bottom-md'>“A Seven-Day Party In The Mediterranean.”</p>
                      <Image className='max-width-logo-sm width-auto' src="https://assets.theyachtweek.com/wp-content/uploads/2024/03/forbes.png" width="300" height="150" alt="Forbes" />
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="flex justify-center">
                    <div className="margin-md radius-md relative text-center">
                      <p className='margin-bottom-md'>“My Yacht Week experience was an absolute 10 out of mothereffing 10”</p>
                      <Image className='max-width-logo-sm width-auto' src="https://assets.theyachtweek.com/wp-content/uploads/2024/03/cosmo.png" width="300" height="150" alt="Cosmopolitan" />
                    </div>
                  </div>
                </div>
              </div>
            </ComponentWrapper>
          </ComponentVerticalSpacing>
        </div>


        {/* Intro, Destinations, Festivals Sliders */}

        <div className='d8-u-brand-secondary-bg padding-top-md padding-bottom-sm margin-bottom-lg width-100% overflow-hidden'>
          <ComponentVerticalSpacing>
            <ComponentWrapper>
              <div className="container max-width-lg margin-auto margin-bottom-lg text-center d8-u-color-brand-primary text-component">
                <h2 className="max-width-md text-xl margin-auto d8-u-color-brand-primary">Pick your own adventure</h2>
                <p className="max-width-sm margin-y-lg margin-x-auto">For over 18 years, we&apos;ve brought together a curated crowd with great DJs and creators for exclusive parties, circle rafts and adventure. Get started by choosing a route or a festival.</p>
              </div>
            </ComponentWrapper>

          </ComponentVerticalSpacing>
          <ComponentVerticalSpacing>
            <ComponentWrapper className="margin-bottom-lg relative">
              <TribeSymbol className="icon--xxl corner-symbol bg-icon bg-icon-left" fill="#FCF0E6" />
              <div className="container max-width-lg margin-auto margin-bottom-lg text-center d8-u-color-brand-primary text-component">
                <h2 className="max-width-md text-lg margin-auto d8-u-color-brand-primary relative">Destinations</h2>
                <p className="max-width-sm margin-y-lg margin-x-auto relative">The best of Yacht Week in original and new destinations. Expect circle rafts, secluded coves and the best eating, drinking and partying on the planet whichever route you choose.</p>
              </div>

              <div className='max-width-lg margin-auto'>
                <CardSlider snap hideFade>
                  {mainDestinations && mainDestinations.map(destination => (
                    <div key={`${JSON.stringify(destination)}`} className="padding-sm height-100% padding-bottom-lg">
                      <Link
                        href={`/${destination.slug}`}
                      >
                        <Card
                          template="ImageCard"
                          imageCardTemplate="DestinationWide"
                          size="xl"
                          className="d8-u-hover-shadow-xl overflow-hidden width-100% destination-card-wide"
                          bgImage={{
                            url: destination.featured_image_src,
                            alt: destination.seo_title
                          }}
                          description={destination.acf.description && destination.acf.description || null}
                          noComponentPadding
                          price={destination.minPrice || null}
                          textMain={destination.title?.rendered}
                          whiteFont
                          dates={destination.acf.info.months || null}
                          overlay
                          soldOut={destination.destinationStatus !== 'coming_soon' && !destination.minPrice}
                        />
                      </Link>
                    </div>
                  ))}
                </CardSlider>
                <div className='text-center padding-top-md margin-bottom-md'>
                  <Link className='btn btn--primary max-width-lg' href='/destination-guide'>Browse the guide</Link>
                </div>
              </div>

            </ComponentWrapper>

            <ComponentWrapper>
              <div className="container max-width-lg margin-auto margin-bottom-lg text-center d8-u-color-brand-primary text-component relative">
                <FancySun className="icon--xxl corner-symbol bg-icon bg-icon-right" fill="#FCF0E6" />
                <h2 className="max-width-md text-lg margin-auto d8-u-color-brand-primary relative">Festivals</h2>
                <p className="max-width-sm margin-y-lg margin-x-auto relative">All the fun of Yacht Week, with a curated soundtrack, leading DJs and special events to match. From techo to hip hop, disco to house, we&apos;ve got the soundtrack to your summer covered.</p>
              </div>

              <div className='max-width-lg margin-auto'>
                <CardSlider snap hideFade>
                  {festivals && festivals.map(destination => (
                    <div key={`${JSON.stringify(destination)}`} className="padding-sm height-100% padding-bottom-lg">
                      <Link
                        href={`/${destination.slug}`}
                        className="destination-card-wide"
                      >
                        <Card
                          template="ImageCard"
                          imageCardTemplate="DestinationWide"
                          className="d8-u-hover-shadow-xl overflow-hidden destination-card-wide width-100%"
                          size='xl'
                          bgImage={{
                            url: destination.featured_image_src,
                            alt: destination.seo_title
                          }}
                          description={destination.acf.description && destination.acf.description || null}
                          noComponentPadding
                          price={destination.minPrice || null}
                          textMain={destination.title?.rendered}
                          whiteFont
                          dates={destination.acf.info.months || null}
                          overlay
                          soldOut={destination.destinationStatus !== 'coming_soon' && !destination.minPrice}
                          pills={[{ title: 'Festival', type: PILL_TYPES.FESTIVAL }]}
                        />
                      </Link>
                    </div>
                  ))}
                </CardSlider>
                <div className='text-center padding-top-md margin-bottom-md'>
                  <Link className='btn btn--primary max-width-lg' href='/destination-guide'>Browse the guide</Link>
                </div>
              </div>
            </ComponentWrapper>
          </ComponentVerticalSpacing>
        </div>



        {/* Reviews */}

        {page.acf.reviews && !isAdsLandingPage && (
          <div className="overflow-hidden" id="reviews">
            <Reviews reviews={page.acf.reviews} />
          </div>
        )}


        <ComponentVerticalSpacing noBottom>
          <ComponentWrapper className="text-center d8-u-color-brand-primary">
            <h2 className='max-width-md text-xl margin-auto d8-u-color-brand-primary relative'>What makes Yacht Week different</h2>
            <p className='max-width-sm margin-y-lg margin-x-auto relative'>At Yacht Week, we put experience first. Our team is constantly working to plan out the best possible experience at every step of your journey.</p>
          </ComponentWrapper>
        </ComponentVerticalSpacing>

        <div className='overflow-hidden'>
          <ComponentVerticalSpacing>
            <ComponentWrapper className="max-width-md relative">
              <FancySun className="icon--xxl corner-symbol bg-icon bg-icon-right bg-icon-alt" fill="var(--color-brand-highlight-2)" />
              <div className="grid grid-gap-lg items-center items-col-reverse items-row@md relative">
                <div className="col-6@md order-1">
                  <Image width={1000} height={1000} src="https://assets.theyachtweek.com/wp-content/uploads/2024/03/curated.jpg" alt="SOL" className="width-100% width-auto@md max-width-100% d8-u-radius-md" />
                </div>
                <div className="col-6@md order-2 order-1@md">
                  <div className="relative padding-right-lg">
                    <h3 className="text-lg margin-top-sm margin-bottom-xxs d8-u-color-brand-primary">
                      <span>Everything curated</span><br />
                    </h3>
                    <p className='margin-bottom-md'>Our exclusive Yacht Week routes are built on 18 years of experience. Expect expertly curated events, quality DJs, diverse activities, non-stop excitement and unforgettable vibes.</p>
                  </div>
                </div>
              </div>
            </ComponentWrapper>
          </ComponentVerticalSpacing>
          <ComponentVerticalSpacing>
            <ComponentWrapper className="max-width-md relative">
              <AnchorSymbol className="icon--xxl corner-symbol bg-icon bg-icon-left bg-icon-alt bg-icon-top-gap" fill="var(--color-brand-highlight-2)" />
              <div className="grid grid-gap-lg items-center items-col-reverse items-row@md relative">
                <div className="col-6@md order-2">
                  <Image width={1000} height={1000} src="https://assets.theyachtweek.com/wp-content/uploads/2024/03/exclusive.jpg" alt="SOL" className="width-100% width-auto@md max-width-100% d8-u-radius-md" />
                </div>
                <div className="col-6@md order-2 order-1@md">
                  <div className="relative padding-right-lg">
                    <h3 className="text-lg margin-top-sm margin-bottom-xxs d8-u-color-brand-primary">
                      <span>Luxe, Local & Exclusive</span><br />
                    </h3>
                    <p className='margin-bottom-md'>Our expert guides curate the best places to sail, explore and dance. Expect great views, great food and great parties in secret local spots and the top, exclusive venues. </p>
                  </div>
                </div>
              </div>
            </ComponentWrapper>
          </ComponentVerticalSpacing>
          <ComponentVerticalSpacing>
            <ComponentWrapper className="max-width-md relative">
              <TribeSymbol className="icon--xxl corner-symbol bg-icon bg-icon-right bg-icon-alt" fill="var(--color-brand-highlight-2)" />
              <div className="grid grid-gap-lg items-center items-col-reverse items-row@md relative">
                <div className="col-6@md order-1">
                  <Image width={1000} height={1000} src="https://assets.theyachtweek.com/wp-content/uploads/2024/03/quality-scaled.jpg" alt="SOL" className="width-100% width-auto@md max-width-100% d8-u-radius-md" />
                </div>
                <div className="col-6@md order-2 order-1@md">
                  <div className="relative padding-right-lg">
                    <h3 className="text-lg margin-top-sm margin-bottom-xxs d8-u-color-brand-primary">
                      <span>Quality always built in</span><br />
                    </h3>
                    <p className='margin-bottom-md'>Experience the best yachts, top-notch service on and off the water, and meticulously planned events and parties that set the standard for excellence.</p>
                  </div>
                </div>
              </div>
            </ComponentWrapper>
          </ComponentVerticalSpacing>
        </div>
        <div className='d8-u-brand-secondary-bg padding-top-md padding-bottom-md'>
          {/* 3 col */}
          <ComponentVerticalSpacing>
            <ComponentWrapper>
              <div className="container max-width-lg margin-auto margin-bottom-lg text-center d8-u-color-brand-primary text-component">
                <h2 className="max-width-md text-xl margin-auto d8-u-color-brand-primary">How it works</h2>
                <p className="max-width-md margin-y-lg margin-x-auto">Gather your crew and set sail on a the adventure of a lifetime.</p>
              </div>
            </ComponentWrapper>


            <ComponentWrapper>
              <div className="grid grid-gap-lg grid-gap-xxl-custom d8-u-color-brand-primary text-center items-center text-left@md">
                <div className="col-4@md relative text-center">
                  <div className="">
                    <FancySun className="icon--xxl corner-symbol" fill="var(--color-brand-primary)" />
                    <h3 className="text-lg margin-top-sm margin-bottom-xxs d8-u-color-brand-primary">
                      <span>Pick your route</span><br />
                    </h3>
                    <p className='margin-bottom-md'>Start with a destination or  a festival for a specific vibe and soundtrack.
                    </p>
                    <Link className='btn btn--ghost-brand-primary max-width-lg' href="/destination-guide">Pick your route</Link>
                  </div>
                </div>
                <div className="col-4@md relative text-center">
                  <div className="">
                    <Sunset className="icon--xxl corner-symbol" fill="var(--color-brand-primary)" />
                    <h3 className="text-lg margin-top-sm margin-bottom-xxs d8-u-color-brand-primary">
                      <span>Pick your yacht</span><br />
                    </h3>
                    <p className='margin-bottom-md'>Choose sailing excitement or space to play. Every yacht comes with an expert skipper included.</p>
                    <Link className='btn btn--ghost-brand-primary max-width-lg' href="/how-it-works">Discover our yachts</Link>
                  </div>
                </div>
                <div className="col-4@md text-center">
                  <div className="">
                    <AnchorSymbol className="icon--xxl corner-symbol" fill="var(--color-brand-primary)" />
                    <h3 className="text-lg margin-top-sm margin-bottom-xxs d8-u-color-brand-primary">
                      <span>Create or join a crew</span><br />
                    </h3>
                    <p className='margin-bottom-md'>Invite your friends to join your crew, or join one by booking a cabin with a friend.
                    </p>
                    <Link className='btn btn--ghost-brand-primary max-width-lg' href="/cabins">Explore cabins</Link>
                  </div>
                </div>
              </div>
            </ComponentWrapper>



            {/* payment plans */}
            <ComponentWrapper className="margin-top-xl">
              <div className="container max-width-lg margin-auto margin-bottom-0 text-center d8-u-color-brand-primary text-component">
                <h2 className="max-width-md text-xl margin-auto d8-u-color-brand-primary">Use our plans to spread the cost</h2>
                <p className="max-width-md margin-y-0 margin-x-auto">We make it easy to split the cost between your crew and pay in instalments.</p>
              </div>
            </ComponentWrapper>
            {howItWorksPage.acf?.payment_methods_section && (
              <Payment data={howItWorksPage.acf.payment_methods_section} isHomePage={true} />
            )}
          </ComponentVerticalSpacing>
        </div>

        {/* subscribe form */}


        {page.acf.signupmodal && page.acf.signupmodal.show && (
          <EmailSignupModal
            timeout={30000}
            mobileLink={page.acf.signupmodal.mobilelink}
            title={page.acf.signupmodal.title}
            text={page.acf.signupmodal.text}
            image={page.acf.signupmodal.image}
            addressBookId={page.acf.signupmodal.addressbookid}
          />
        )}
      </div>
    </PageLayout>
  );
};

export default HomepageWrapper;
