import React, { Fragment } from 'react';
import {
  ComponentWrapper,
  Carousel,
  Card,
  Avatar,
  AVATAR_TYPES,
  AVATAR_COLORS,
  AVATAR_SIZES,
  BREAKPOINTS,
  COLORS,
  Svg,
  Media
} from 'day8-react/build/new-components';
import { formattingHelpers } from 'day8-react';
import Svg2 from './Svg';
import { WpPageType, WpImageType } from '../../globalTypes';

type ReviewsType = {
  reviews: {
    reviews: WpPageType[]
    image: WpImageType
    text: string
    title: string
  }
}

const Reviews = ({ reviews }: ReviewsType) => {
  const reviewsMap = reviews.reviews.filter(r => r.id);
  return (
    <ComponentWrapper>
      <div className="text-component margin-bottom-xl text-center margin-top-md">
        <h2>{reviews.title}</h2>
        <p className="margin-bottom-lg">{reviews.text}</p>
        {/* <a className="text-center margin-top-sm margin-auto block d8-u-hover" href="https://www.trustpilot.com/review/theyachtweek.com" target="_blank" style={{ maxWidth: 124 }}>
          <Svg name="4-5" fill="#000000" />
        </a> */}
      </div>
      <div className="position-relative">
        <div className="absolute" style={{ top: 0, left: '15%', opacity: 0.75 }}>
          <Svg2 name="dots" fill={COLORS.PRIMARY} />
        </div>
        <div className="absolute" style={{ bottom: 60, right: '50%', opacity: 0.55 }}>
          <Svg2 name="dots" fill="#ed662f" />
        </div>
        <div className="absolute" style={{ bottom: '50%', right: '15%', opacity: 0.5 }}>
          <Svg2 name="dots" fill={COLORS.PRIMARY} />
        </div>
        <Media lessThan={BREAKPOINTS.MD}>
          <div className="max-width-md margin-auto padding-md review-carousel">
            <Carousel
              equalHeights
              showEdges
              outerDots
              settings={{
                dots: true,
                autoplay: true,
                autoplaySpeed: 8000,
                slidesToShow: 2,
                slidesToScroll: 1,
                responsive: [
                  {
                    breakpoint: 640,
                    settings: {
                      slidesToShow: 1,
                      slidesToScroll: 1
                    }
                  }
                ]
              }}
            >
              {reviewsMap.map(review => (
                <Fragment key={JSON.stringify(review)}>{review.status === 'publish' && (
                  <div key={JSON.stringify(review)} className="padding-sm height-100%">
                    <Card>
                      <div className="justify-start height-100%">
                        <div className="flex">
                          <div className="margin-right-sm">
                            {review.featured_image_src?.includes('default.png') ? (
                              <Avatar
                                avatarType={AVATAR_TYPES.CIRCLE}
                                avatarSize={AVATAR_SIZES.MEDIUM}
                                avatarColor={AVATAR_COLORS.PRIMARY}
                                initials={formattingHelpers.getInitialsFromName(
                                  review.title?.rendered && review.title?.rendered.split(' ')[0],
                                  review.title?.rendered && review.title?.rendered.split(' ')[1]
                                )}
                              />
                            ) : (
                              <Avatar
                                avatarType={AVATAR_TYPES.CIRCLE}
                                avatarSize={AVATAR_SIZES.MEDIUM}
                                avatarColor={AVATAR_COLORS.PRIMARY}
                                image={{
                                  url: review.featured_image_src,
                                  alt: review.title?.rendered
                                }}
                              />
                            )}

                          </div>
                          <div>
                            <p className="margin-top-xs d8-u-text-bold">{review.title?.rendered}</p>
                          </div>
                        </div>
                        <div className="margin-top-sm" dangerouslySetInnerHTML={{ __html: review.content?.rendered || '' }} />
                      </div>
                    </Card>
                  </div>
                )}
                </Fragment>
              ))}
            </Carousel>
          </div>
        </Media>
        <Media greaterThanOrEqual={BREAKPOINTS.MD}>
          <div className="max-width-xxs margin-auto review-carousel">
            <Carousel
              equalHeights
              outerDots
              settings={{
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: true,
                autoplay: true,
                autoplaySpeed: 8000
              }}
            >
              {reviewsMap.map(review => (
                <Fragment key={JSON.stringify(review)}>{review.status === 'publish' && (
                  <div key={JSON.stringify(review)} className="padding-y-sm padding-x-lg height-100%">
                    <Card>
                      <div className="justify-start height-100%">
                        <div className="flex">
                          <div className="margin-right-sm">
                            {review.featured_image_src?.includes('default.png') ? (
                              <Avatar
                                avatarType={AVATAR_TYPES.CIRCLE}
                                avatarSize={AVATAR_SIZES.MEDIUM}
                                avatarColor={AVATAR_COLORS.PRIMARY}
                                initials={formattingHelpers.getInitialsFromName(
                                  review.title?.rendered && review.title?.rendered.split(' ')[0],
                                  review.title?.rendered && review.title?.rendered.split(' ')[1]
                                )}
                              />
                            ) : (
                              <Avatar
                                avatarType={AVATAR_TYPES.CIRCLE}
                                avatarSize={AVATAR_SIZES.MEDIUM}
                                avatarColor={AVATAR_COLORS.PRIMARY}
                                image={{
                                  url: review.featured_image_src,
                                  alt: review.title?.rendered
                                }}
                              />
                            )}

                          </div>
                          <div>
                            <p className="margin-top-xs d8-u-text-bold">{review.title?.rendered}</p>
                          </div>
                        </div>
                        <div className="margin-top-sm" dangerouslySetInnerHTML={{ __html: review.content?.rendered || '' }} />
                      </div>
                    </Card>
                  </div>
                )}
                </Fragment>
              ))}
            </Carousel>
          </div>
        </Media>
      </div>
    </ComponentWrapper>
  );
};

export default Reviews;
